<template>
    <app-navigation :heading="activeProject.name"
                    class="mb-4">
        <template #toolbar>
            <v-spacer></v-spacer>
            <view-selector :project-id="activeProject._id"></view-selector>
        </template>
        <div class="steps-container d-flex fill-height">
            <v-col v-for="status in ['Accepted', 'Completed', 'Declined', 'Withdrawn']"
                   :key="status"
                   class="step-column">
                <h4 class="text-center mb-2">{{status}}</h4>
                <submission-summary-card v-for="sub in $data[status.toLowerCase()]"
                                         :key="sub._id"
                                         :submission="sub"
                                         :to="`/projects/${activeProject._id}/submissions/${sub._id}`"></submission-summary-card>
            </v-col>
        </div>
    </app-navigation>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';
    import beforeEnterGuard from '../router/beforeEnterGuard';

    import appNavigation from '@/components/app-navigation';
    import viewSelector from '@/modules/projects/components/view-selector';
    import submissionSummaryCard from '@/components/submission-summary-card';

    export default {
        name: 'finalised-board',
        components: {
            appNavigation,
            viewSelector,
            submissionSummaryCard,
        },
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            submissions: {},
            accepted: [],
            completed: [],
            declined: [],
            withdrawn: [],
        }),
        computed: {
            ...mapState('organisation/projects', ['activeProject']),
            ...mapGetters('organisation/projects', ['activeProjectUrl']),
        },
        methods: {
            loadFinalisedSubs() {
                this.$store.commit('updateStatus', { isLoading: true });
                this.$http
                    .get(`${this.activeProjectUrl}/submissions/finalised`)
                    .then(({ data: subs }) => {
                        subs.forEach(s => {
                            this.submissions[s._id] = s;

                            const statusArray =
                                s.status === 'Accepted'
                                    ? this.accepted
                                    : s.status === 'Completed'
                                    ? this.completed
                                    : s.status === 'Declined'
                                    ? this.declined
                                    : s.status === 'Withdrawn'
                                    ? this.withdrawn
                                    : undefined;

                            if (statusArray) statusArray.push(s);
                        });
                        this.submissions = subs;
                        this.$store.commit('updateStatus', { isLoading: false });
                    });
            },
            displayDate(date) {
                return this.$displayDate(date);
            },
        },
        beforeRouteUpdate (to, from, next) {
            return beforeEnterGuard(this.$store, to, from, next, true);
        },
        created() {
            this.loadFinalisedSubs();
        },
    };
</script>

<!-- Shares styles with assigned-board-->
<style>
    .steps-container {
        background-color: #e7e7e7;
        border-top: 0;
        padding: 4px;
        padding-bottom: 0;
        overflow-y: visible;
    }

    .step-column {
        width: calc(25vw - 6px);
        min-width: calc(25vw - 6px);
        background-color: #fafafa;
        border-right: 4px solid #e7e7e7;
        border-bottom: 4px solid #e7e7e7;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .step-column:last-child {
        border-right: 0;
    }
</style>