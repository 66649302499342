<template>
    <v-btn-toggle background-color="primary"
                  dense
                  light
                  mandatory
                  borderless
                  rounded>
        <v-btn :to="`/projects/${projectId}/worktrays`"
               title="Worktrays for this project"
               text
               color="white">Worktrays</v-btn>
        <v-btn :to="`/projects/${projectId}/assigned`"
               title="Submissions that are currently assigned"
               text
               color="white">Assigned</v-btn>
        <v-btn :to="`/projects/${projectId}/finalised`"
               title="Submissions that have been completed, declined or withdrawn"
               text
               color="white">Finalised</v-btn>
        <v-btn :to="`/projects/${projectId}/issues`"
               title="Submissions that have have unresolved issues"
               text
               color="white">Issues</v-btn>
    </v-btn-toggle>
</template>
<script>
    export default {
        name: 'view-selector',
        props: {
            projectId: {
                type: String,
                required: true,
            },
        },
    };
</script>