<template>
    <v-card class="submission-summary-card"
            :to="to">
        <v-card-title class="justify-space-between flex-nowrap pb-1">
            <div class="submission-title text-truncate pr-2">
                <span :title="submission.title">{{submission.title}}</span>
            </div>
            <submission-indicators :submission="submission"></submission-indicators>
        </v-card-title>
        <v-card-text>
            <slot>
                <table>
                    <tbody>
                        <tr>
                            <td>Created:</td>
                            <td><em>{{$displayDate(submission.createdDate)}}</em></td>
                        </tr>
                    </tbody>
                </table>
            </slot>
            <table>
            </table>
        </v-card-text>
    </v-card>
</template>
<script>
    import submissionIndicators from '@/components/submission-indicators';

    export default {
        name: 'submission-summary-card',
        components: {
            submissionIndicators,
        },
        props: {
            submission: {
                type: Object,
                required: true,
            },
            to: String,
        },
    };
</script>
<style>
    .col > .submission-summary-card:not(:last-child) {
        margin-bottom: 0.5em;
    }
</style>